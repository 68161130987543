<template>
    <form
        novalidate
        @submit.prevent="submitForm"
    >
        <h1 class="form-body-title">
            {{ $t('views_user_modalLogin_0012') }}
        </h1>
        <p class="small">
            {{ $t('views_user_modallogin_headline') }}
        </p>

        <!-- email input -->
        <div class="mb-3">
            <input
                v-model="form.data.email"
                type="email"
                class="form-control"
                :class="{ 'is-invalid': form.submitted && $v.form.data.email.$error
                    || form.serverErrors.email }"
                :placeholder="$t('views_user_login_0007')"
                @keyup="clearServerErrors($event, 'email')"
            >
            <div
                v-if="form.submitted"
                class="invalid-feedback"
                :class="{ 'd-block': form.serverErrors.email }"
            >
                <span v-if="!$v.form.data.email.required">
                    {{ $t("controllers_user_actionSignup_0002") }}
                </span>
                <span v-if="!$v.form.data.email.email">
                    {{ $t("controllers_user_actionSignup_0004") }}
                </span>
                <!-- api error -->
                <span
                    v-if="!$v.form.data.email.$error && form.serverErrors.email"
                    v-html="form.serverErrors.email"
                />
                <!-- /api error -->
            </div>
        </div>
        <!-- /email input -->

        <!-- password input -->
        <div class="mb-3">
            <input
                v-model="form.data.password"
                type="password"
                class="form-control"
                autocomplete="off"
                :class="{ 'is-invalid': form.submitted && $v.form.data.password.$error
                    || form.serverErrors.password }"
                :placeholder="$t('views_user_login_0008')"
                @keyup="clearServerErrors($event, 'password')"
            >
            <div
                v-if="form.submitted"
                class="invalid-feedback"
                :class="{ 'd-block': form.serverErrors.password }"
            >
                <span v-if="!$v.form.data.password.required">
                    {{ $t("controllers_user_actionSignup_0005") }}
                </span>
                <!-- api error -->
                <span
                    v-if="$v.form.data.password.required && form.serverErrors.password"
                    v-html="form.serverErrors.password"
                />
                <!-- /api error -->
            </div>
        </div>
        <!-- /password input -->

        <div class="mb-3 form-links">
            <base-btn
                variant="link"
                class="px-0"
                @click.native="openModalUser(MODAL_USER_TYPES.RESET_PASSWORD)"
            >
                {{ $t('views_user_modalLogin_0009') }}
            </base-btn>
            <base-btn
                variant="link"
                class="px-0"
                @click.native="openModalUser('autoLogin')"
            >
                {{ $t('views_user_modallogin_autologin') }}
            </base-btn>
        </div>

        <div
            v-if="form.serverErrors.general"
            class="text-center text-danger small mb-3"
        >
            {{ form.serverErrors.general }}
        </div>

        <base-btn
            :loading="form.isLoading"
            :disabled="$v.form.data.$error || form.isLoading"
            variant="primary"
            class="btn-block mb-4"
            type="submit"
        >
            {{ $t('views_user_modalLogin_0012') }}
        </base-btn>

        <div>
            {{ $t('views_user_login_0004') }}
            <base-btn
                variant="link"
                class="py-0"
                @click.native="openModalUser(MODAL_USER_TYPES.REGISTER)"
            >
                {{ $t('views_user_signup_0014') }}
            </base-btn>
        </div>

        <form-login-social
            @social-login="(data) => socialLogin(data)"
            @clear-server-errors="clearServerErrors()"
        />

        <div
            v-if="form.serverErrors.social"
            class="text-center text-danger small mt-3"
        >
            {{ form.serverErrors.social }}
        </div>
    </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { MODAL_USER_TYPES } from '../utils/constants';
import { authMixin } from '../utils/mixins';
import FormLoginSocial from './FormLoginSocial.vue';

const initialFormState = () => ({
    data: {
        email: '',
        password: '',
    },
    serverErrors: {
        email: '',
        password: '',
        general: '',
        social: '',
    },
    submitted: false,
    isLoading: false,
});

export default {
    name: 'FormLogin',

    components: {
        FormLoginSocial,
    },

    mixins: [
        authMixin,
        validationMixin,
    ],

    props: {
        nextUrl: {
            type: String,
            default: null,
        },
    },

    validations: {
        form: {
            data: {
                email: { required, email },
                password: { required },
            },
        },
    },

    data: () => ({
        form: {
            ...initialFormState(),
            isSocialLoading: {
                facebook: false,
                google: false,
            },
            serverErrors: {
                social: '',
            },
        },
    }),

    created() {
        this.MODAL_USER_TYPES = MODAL_USER_TYPES;
    },

    methods: {
        submitForm() {
            this.form.submitted = true;
            this.$v.form.data.$touch();

            if (this.$v.form.data.$error) {
                return;
            }

            this.loginUser(this.form.data.email, this.form.data.password);
        },

        socialLogin(data) {
            const [identifier, password = null, loginType = null] = data;
            this.loginUser(identifier, password, loginType);
        },

        clearServerErrors() {
            this.form.serverErrors = {
                social: '',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./../scss/required";

.form-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: $spacer;
}
</style>
