var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("hr", {
        staticClass: "my-4",
        attrs: { "data-text": _vm.$t("views_user_modal_separator") },
      }),
      _vm._v(" "),
      _vm.isMobileApp && _setup.$store.state.ui.switch.app.formLogin.btnGoogle
        ? _c(_setup.BtnLoginGoogleApp, {
            attrs: {
              loading: _vm.form.isSocialLoading.google,
              "login-user-function": _vm.loginUser,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("clear-server-errors")
              },
            },
          })
        : !_vm.isMobileApp
        ? _c(_setup.BtnLoginGoogleWeb, {
            attrs: { loading: _vm.form.isSocialLoading.google },
            on: {
              click: function ($event) {
                return _vm.$emit("clear-server-errors")
              },
              loginUser: function ($event) {
                return _vm.$emit("social-login", [
                  $event.gToken,
                  null,
                  "google",
                ])
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobileApp && _setup.$store.state.ui.switch.app.formLogin.btnFacebook
        ? _c(_setup.BtnLoginFacebookApp, {
            attrs: {
              loading: _vm.form.isSocialLoading.facebook,
              "login-user-function": _vm.loginUser,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("clear-server-errors")
              },
            },
          })
        : !_vm.isMobileApp
        ? _c(_setup.BtnLoginFacebookWeb, {
            attrs: { loading: _vm.form.isSocialLoading.facebook },
            on: {
              click: function ($event) {
                return _vm.$emit("clear-server-errors")
              },
              loginUser: function ($event) {
                return _vm.$emit("social-login", [
                  $event.fToken,
                  null,
                  "facebook",
                ])
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobileApp ||
      (_vm.isMobileApp && _setup.$store.state.ui.switch.app.formLogin.btnApple)
        ? _c(
            "base-btn",
            {
              staticClass: "bg-apple btn-block",
              attrs: {
                loading: _vm.form.isSocialLoading.apple,
                variant: "link",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.loginWithApple.apply(null, arguments)
                },
              },
            },
            [
              _c("base-icon", { attrs: { name: "apple" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("views_user_signup_0021")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.form.serverErrors.email
        ? _c("div", { staticClass: "invalid-feedback d-block" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.form.serverErrors.email) },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }