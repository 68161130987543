var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _c("h1", { staticClass: "form-body-title" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$t("views_user_modalLogin_0012")) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "small" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("views_user_modallogin_headline")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.data.email,
              expression: "form.data.email",
            },
          ],
          staticClass: "form-control",
          class: {
            "is-invalid":
              (_vm.form.submitted && _vm.$v.form.data.email.$error) ||
              _vm.form.serverErrors.email,
          },
          attrs: {
            type: "email",
            placeholder: _vm.$t("views_user_login_0007"),
          },
          domProps: { value: _vm.form.data.email },
          on: {
            keyup: function ($event) {
              return _vm.clearServerErrors($event, "email")
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form.data, "email", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.form.submitted
          ? _c(
              "div",
              {
                staticClass: "invalid-feedback",
                class: { "d-block": _vm.form.serverErrors.email },
              },
              [
                !_vm.$v.form.data.email.required
                  ? _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("controllers_user_actionSignup_0002")) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.form.data.email.email
                  ? _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("controllers_user_actionSignup_0004")) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.form.data.email.$error && _vm.form.serverErrors.email
                  ? _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.form.serverErrors.email),
                      },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.data.password,
              expression: "form.data.password",
            },
          ],
          staticClass: "form-control",
          class: {
            "is-invalid":
              (_vm.form.submitted && _vm.$v.form.data.password.$error) ||
              _vm.form.serverErrors.password,
          },
          attrs: {
            type: "password",
            autocomplete: "off",
            placeholder: _vm.$t("views_user_login_0008"),
          },
          domProps: { value: _vm.form.data.password },
          on: {
            keyup: function ($event) {
              return _vm.clearServerErrors($event, "password")
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form.data, "password", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm.form.submitted
          ? _c(
              "div",
              {
                staticClass: "invalid-feedback",
                class: { "d-block": _vm.form.serverErrors.password },
              },
              [
                !_vm.$v.form.data.password.required
                  ? _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("controllers_user_actionSignup_0005")) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$v.form.data.password.required &&
                _vm.form.serverErrors.password
                  ? _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.form.serverErrors.password),
                      },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-3 form-links" },
        [
          _c(
            "base-btn",
            {
              staticClass: "px-0",
              attrs: { variant: "link" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openModalUser(_vm.MODAL_USER_TYPES.RESET_PASSWORD)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("views_user_modalLogin_0009")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "base-btn",
            {
              staticClass: "px-0",
              attrs: { variant: "link" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openModalUser("autoLogin")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("views_user_modallogin_autologin")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.form.serverErrors.general
        ? _c("div", { staticClass: "text-center text-danger small mb-3" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.form.serverErrors.general) + "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "base-btn",
        {
          staticClass: "btn-block mb-4",
          attrs: {
            loading: _vm.form.isLoading,
            disabled: _vm.$v.form.data.$error || _vm.form.isLoading,
            variant: "primary",
            type: "submit",
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("views_user_modalLogin_0012")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("views_user_login_0004")) +
              "\n        "
          ),
          _c(
            "base-btn",
            {
              staticClass: "py-0",
              attrs: { variant: "link" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openModalUser(_vm.MODAL_USER_TYPES.REGISTER)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("views_user_signup_0014")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("form-login-social", {
        on: {
          "social-login": (data) => _vm.socialLogin(data),
          "clear-server-errors": function ($event) {
            return _vm.clearServerErrors()
          },
        },
      }),
      _vm._v(" "),
      _vm.form.serverErrors.social
        ? _c("div", { staticClass: "text-center text-danger small mt-3" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.form.serverErrors.social) + "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }