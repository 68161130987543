<template>
    <div>
        <hr
            class="my-4"
            :data-text="$t('views_user_modal_separator')"
        >

        <btn-login-google-app
            v-if="isMobileApp && $store.state.ui.switch.app.formLogin.btnGoogle"
            :loading="form.isSocialLoading.google"
            :login-user-function="loginUser"
            @click="$emit('clear-server-errors')"
        />
        <btn-login-google-web
            v-else-if="!isMobileApp"
            :loading="form.isSocialLoading.google"
            @click="$emit('clear-server-errors')"
            @loginUser="$emit('social-login', [$event.gToken, null, 'google'])"
        />
        <btn-login-facebook-app
            v-if="isMobileApp && $store.state.ui.switch.app.formLogin.btnFacebook"
            :loading="form.isSocialLoading.facebook"
            :login-user-function="loginUser"
            @click="$emit('clear-server-errors')"
        />
        <btn-login-facebook-web
            v-else-if="!isMobileApp"
            :loading="form.isSocialLoading.facebook"
            @click="$emit('clear-server-errors')"
            @loginUser="$emit('social-login', [$event.fToken, null, 'facebook'])"
        />
        <base-btn
            v-if="!isMobileApp || (isMobileApp && $store.state.ui.switch.app.formLogin.btnApple)"
            :loading="form.isSocialLoading.apple"
            class="bg-apple btn-block"
            variant="link"
            @click.native="loginWithApple"
        >
            <base-icon name="apple" />
            <span v-html="$t('views_user_signup_0021')" />
        </base-btn>
        <!-- api error -->
        <div
            v-if="form.serverErrors.email"
            class="invalid-feedback d-block"
        >
            <span v-html="form.serverErrors.email" />
        </div>
    </div>
</template>

<script>
import { onUnmounted, defineAsyncComponent } from 'vue';
import { useHead } from '@unhead/vue';
import { useCurrentInstance } from '../composables/useCurrentInstance';
import { authMixin } from '../utils/mixins';
import { SOCIAL, SOURCE } from '../utils/constants';

const BtnLoginGoogleApp = defineAsyncComponent(() => import('./BtnLoginGoogleApp.vue'));
const BtnLoginGoogleWeb = defineAsyncComponent(() => import('./BtnLoginGoogleWeb.vue'));
const BtnLoginFacebookApp = defineAsyncComponent(() => import('./BtnLoginFacebookApp.vue'));
const BtnLoginFacebookWeb = defineAsyncComponent(() => import('./BtnLoginFacebookWeb.vue'));

export default {
    name: 'FormLoginSocial',

    components: {
        BtnLoginGoogleApp,
        BtnLoginGoogleWeb,
        BtnLoginFacebookApp,
        BtnLoginFacebookWeb,
    },

    mixins: [authMixin],

    data: () => ({
        form: {
            isSocialLoading: {
                facebook: false,
                google: false,
            },
            serverErrors: {},
        },
    }),

    computed: {
        paramsApi() {
            return this.$store.state.params.api;
        },
        isMobileApp() {
            return this.$store.state.params.source === SOURCE.APP;
        },
    },

    methods: {
        loginWithApple() {
            window.AppleID.auth.signIn().then((response) => {
                const aToken = {
                    identityToken: response.authorization.id_token,
                    authorizationCode: response.authorization.code,
                    ...(response.user && {
                        fullName: {
                            givenName: response.user.name.firstName,
                            familyName: response.user.name.lastName,
                        },
                    }),
                };
                this.$emit('social-login', [
                    btoa(JSON.stringify(aToken)),
                    null,
                    SOCIAL.SOCIAL_TYPES.APPLE,
                ]);
            });
        },
    },
};
</script>

<script setup>
const { $store } = useCurrentInstance();
const paramsApi = $store.state.params.api;

const initLoginWithApple = () => {
    window.AppleID.auth.init({
        clientId: paramsApi.apple.clientId,
        scope: SOCIAL.SOCIAL_SCOPES[SOCIAL.SOCIAL_TYPES.APPLE],
        redirectURI: window.location.origin,
        usePopup: true,
    });
};

useHead({
    script: [
        {
            key: 'appleJs',
            src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
            onload: () => initLoginWithApple(),
        },
    ],
}, { mode: 'client' });

onUnmounted(() => {
    useHead({
        script: [
            { key: 'appleJs' },
        ],
    }, { mode: 'client' });
});
</script>
